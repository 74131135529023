import React from 'react';
import {
  Row, Col, Button, Container, Form, FormGroup, Input,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';
import Header from "components/Headers/Header.js";
import ReactPlayer from 'react-player'
import helpers from '../helpers/common';
import ReactGA from 'react-ga';

const filePath = "https://s3.ap-south-1.amazonaws.com/frenzi-native/Video/1635495163325.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20211029T081337Z&X-Amz-SignedHeaders=host&X-Amz-Expires=899&X-Amz-Credential=AKIASQRFSGGNFYSGYTAX%2F20211029%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=fe1abe7e3a83577cde085bdb1681b9f92b0d2789165b7d81f2e569287ba1076b";


const Index = (props) => {
  const [signedUrl, setSignedUrl] = React.useState('');

  React.useEffect(() => {
  }, [signedUrl])

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  React.useEffect(() => {
    getPresignedUrl(filePath);
  }, [])


  const getPresignedUrl = async (path) => {
    if (path && path != "") {
      const url = await helpers.getPresignedUrl(path);
      if (url) {
        setSignedUrl(url);
      }
    }
  }

  return (
    <>
      <Header />
      <Container>
        <Card className='mt-4 p-3'>
          <h3 className='mb-3'>Welcome</h3>
          <Row className='justify-content-between m-0'>
            <Col sm={4} className="p-0 search-icon-box">
              <Input placeholder='Search' />
              <i className="fas fa-search"></i>
            </Col>
          </Row>
          <Row className='mt-3 d-block d-none'>
            {/* <ReactPlayer controls={true} url={signedUrl} /> */}
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default Index;
